import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Logo from "../../../images/reference/moravske-drevostavby/logo.png"
import Header from "../../../images/reference/moravske-drevostavby/header-marketing.png"

class Moravske extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      background: `#392127`,
    }

    return (
      <Layout>
        <Helmet>
          <title>
            Reference na marketingové práce pro Moravské dřevostavby | igloonet
          </title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//moravske-drevostavby.cz/"
          heading="Reference na marketingové práce pro Moravské dřevostavby"
          odstavec="léto 2014 - současnost"
        >
          <ReferenceBannerContent
            image={{
              src: Header,
            }}
            mainText="Společnost MORAVSKÉ DŘEVOSTAVBY se zabývá výstavbou energeticky úsporných a nízkoenergetických typových dřevostaveb za použití Systému DNK. Ve své práci si berou za vzor moravskou poctivost a pohostinnost."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Zadání</TitleH2>
              <p className="text-center">
                Zvýšit návštěvnost na webu, počet odeslaných poptávek a dotazů
                na stavbu prostřednictvím placené reklamy ve vyhledávání.
                Vytvořit remarketingové kampaně v systému Google AdWords a
                Seznam Sklik.
              </p>
            </PaddingWrapper>
            <PaddingWrapper>
              <TitleH2>Rozsah projektu</TitleH2>
              <p>správa a optimalizace PPC kampaní</p>
            </PaddingWrapper>
            <PaddingWrapper>
              <TitleH2>Historie spolupráce</TitleH2>
              <p>
                Spolupráce se společností MORAVSKÉ DŘEVOSTAVBY začala v roce
                2014, kdy jsme vytvořili nový web na míru. Nyní se staráme o
                jeho další vývoj. V roce 2014 jsme také začali s prvními
                marketingovými aktivitami a nastavili jsme nové PPC kampaně ve
                vyhledávání. Klient byl s výsledky práce spokojen, a tak jsme
                rozšířili spolupráci na remarketingové kampaně.
              </p>
            </PaddingWrapper>
          </HalfWidthBlock>

          <Medailon
            wide
            text="Díky kampaním ve vyhledávání a remarketingovým kampaním se nám podařilo získat nové zakázky."
            name="Ing. Aleš Tajbr, Ph.D."
            position="ředitel"
          />
        </ReferenceContainer>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Moravske
